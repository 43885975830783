import { useEffect, useRef, useState } from 'react'
import { graphql, Script } from 'gatsby'
import { css } from '@emotion/react'
import { useLocaleContext } from '../LocaleContext'
import { hasOwnLocalizedContent } from '../../utils'

const Trustpilot = ({
  entryTitle,
  templateId,
  theme,
  height,
  stars,
  tag,
  linkToTrustpilot,
  template,
  id,
  item,
}) => {
  const trustBoxRef = useRef(null)
  const [isTrustPilotRef, setIsTrustPilotRef] = useState(false)
  const { locale, language } = useLocaleContext()

  useEffect(() => {
    // Checking if trustpilot widget has own localized content
    const initWidget = async () => {
      const showTruspilot = await hasOwnLocalizedContent(
        template,
        id,
        `fields.${item}`,
        locale,
      )

      // If translated content is present, initialize Trustpilot
      if (showTruspilot) {
        const widgetArray =
          window?.Trustpilot?.Modules?.WidgetManagement?.widgets

        if (widgetArray?.length || widgetArray !== 'undefined') {
          window?.Trustpilot?.loadFromElement(trustBoxRef.current, true)
          setIsTrustPilotRef(true)
        }
      }
    }

    initWidget()
  }, [id, isTrustPilotRef, item, locale, template])

  if (!isTrustPilotRef) return <></>
  return (
    <>
      <Script
        type="text/javascript"
        src="https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
      />
      <div
        ref={trustBoxRef}
        className="trustpilot-widget"
        data-locale={locale === 'en' ? 'en-US' : locale}
        data-template-id={templateId}
        data-businessunit-id={process.env.GATSBY_TRUSTPILOT_BUSINESSUNIT_ID}
        data-theme={theme ? 'light' : 'dark'}
        data-style-height={`${height}px`}
        data-style-width="100%"
        data-stars={stars?.join(',')}
        data-review-languages={language === 'nb' ? 'no,nb,nn' : language}
        data-tags={tag ? `${tag}` : ''}
        css={css`
          margin-bottom: 40px;
        `}
      >
        <a href={linkToTrustpilot} target="_blank" rel="noopener noreferrer">
          {entryTitle}
        </a>
      </div>
    </>
  )
}

export const query = graphql`
  fragment Trustpilot on contentful_Trustpilot {
    __typename
    templateId
    theme
    height
    stars
    tag
    linkToTrustpilot
    entryTitle
  }
`

export default Trustpilot
